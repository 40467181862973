import { Box, Grid, IconButton, Paper, Typography, Autocomplete, TextField, Button, Modal, FormControlLabel, Checkbox, FormControl, OutlinedInput, InputLabel } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import CloseIcon from '@mui/icons-material/Close';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import useFetch, { host } from "../../utils/Fetchs";
import { useNavigate } from "react-router-dom";

import Evento from "../../layouts/eventos/Evento"
import ModalEvento from "../../layouts/eventosPorFecha/ModalEvento";

import "dayjs/locale/es";

export default function Formulario() {
    const [categorias, setCategorias] = useState([])

    const [nombre, setNombre] = useState("")
    const [desde, setDesde] = useState(null)
    const [hasta, setHasta] = useState(null)
    const [hora, setHora] = useState(null)
    const [lugar, setLugar] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [categoria, setCategoria] = useState()
    const [link, setLink] = useState("")

    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);

    const [esGratuito, setEsGratuito] = useState(false);

    const [alerta, setAlerta] = useState({ open: false, mensaje: "", titulo: "", icono: null, ok: false });

    const [modal, setModal] = useState(null)

    const inputRef = useRef();

    const navigate = useNavigate();

    const { getFetch, postFetchFormData } = useFetch();

    function enviarEvento() {
        if (!nombre || !desde || !hasta || !hora || !lugar || !descripcion || !categoria || !link || !image) {
            setAlerta({ open: true, mensaje: "Por favor complete todos los campos", titulo: "Faltan campos", icono: <ErrorIcon sx={{ color: "red" }} />, ok: false });
            return;
        }

        const formData = new FormData();

        formData.append("nombre", nombre);
        formData.append("desde", desde.format("YYYY-MM-DD"));
        formData.append("hasta", hasta.format("YYYY-MM-DD"));
        formData.append("hora", hora.format("HH:mm"));
        formData.append("lugar", lugar);
        formData.append("descripcion", descripcion);
        formData.append("categoria", categoria.id);
        formData.append("link", link);
        formData.append("imagen", image);
        formData.append("es_gratuito", esGratuito);


        postFetchFormData(`${host}/eventos/crear/`, formData)
            .then(data => {
                setAlerta({ open: true, mensaje: data.message, titulo: "Su evento fue registrado con éxito", icono: <CheckCircleIcon sx={{ color: "green" }} />, ok: true });
            })
            .catch(error => {
                console.log(error);
                setAlerta({ open: true, mensaje: error.message, titulo: "Ocurrio un error", icono: <ErrorIcon sx={{ color: "red" }} />, ok: false });
            })
    }


    useEffect(() => {
        getFetch(`${host}categorias/`)
            .then(data => {
                setCategorias(data.categorias);
            })
            .catch(error => console.log(error));
    }, []);

    const handleImagenChange = (event) => {
        let file = event.target.files[0];
        if (file) {
            let img = document.createElement("img");
            img.src = URL.createObjectURL(file);
            img.onerror = () => {
                alert('Hubo un error al cargar la imagen.');
            };
            img.onload = () => {
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");

                const originalWidth = img.width;
                const originalHeight = img.height;

                canvas.width = originalWidth;
                canvas.height = originalHeight;

                ctx.drawImage(img, 0, 0, originalWidth, originalHeight);

                canvas.toBlob((blob) => {
                    let newFile = new File([blob], "image.jpeg", { type: "image/jpeg" });
                    setImage(newFile);

                    const imageURL = URL.createObjectURL(newFile);
                    setImageURL(imageURL);

                }, "image/jpeg");
            };
        };
    }


    return (
        <Grid container item sx={{
            paddingTop: "40px",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <Grid item container sx={{
                overflowY: "auto",
                overflowX: "hidden",
                justifyContent: "center",
                paddingTop: "40px",
                paddingBottom: "40px",
            }}>
                <Grid item container gap={8} sx={{
                    alignItems: "center",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    width: "100%",
                    maxWidth: "700px",
                    paddingX: "40px",
                }}>
                    <Paper sx={{
                        width: "100%",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}>
                        <Typography variant="h4">Registra tu evento</Typography>
                        <TextField
                            label="Nombre del evento"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                            <DatePicker
                                label="Desde"
                                value={desde}
                                format="DD/MM/YYYY"
                                onChange={(newValue) => setDesde(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                            <DatePicker
                                label="Hasta"
                                value={hasta}
                                format="DD/MM/YYYY"
                                onChange={(newValue) => setHasta(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={desde}


                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Hora"
                                value={hora}
                                format="HH:mm"
                                onChange={(newValue) => setHora(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <TextField
                            label="Lugar"
                            value={lugar}
                            onChange={(e) => setLugar(e.target.value)}
                        />
                        <FormControl
                            fullWidth>
                            <InputLabel htmlFor="descripcion">Descripción</InputLabel>
                            <OutlinedInput
                                sx={{
                                    paddingBottom: "40px",
                                }}
                                multiline
                                rows={4}
                                fullWidth
                                label="Descripción"
                                value={descripcion}
                                onChange={(e) => {
                                    if (e.target.value.length > 200) return
                                    setDescripcion(e.target.value);
                                }}
                            />
                            <Typography sx={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                                color: "#0000008a",

                            }}>{descripcion.length}/200</Typography>
                        </FormControl>
                        <Autocomplete
                            options={categorias}
                            getOptionLabel={(option) => option.nombre}
                            value={categoria}
                            onChange={(event, newValue) => {
                                setCategoria(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Categoría" />}
                        />

                        <TextField

                            label="Link"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />
                        <Box sx={{
                            height: "100%",
                            aspectRatio: "1/1",
                            cursor: "pointer",
                            position: "relative",
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                        }}

                            onClick={() => inputRef.current.click()}
                        >

                            {image ? <img src={imageURL} alt="Imagen cargada" style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }} /> : <Typography>Subir foto</Typography>}
                            <input type="file" ref={inputRef} style={{ display: "none" }} accept="image/*,image/heic,image/heif" onChange={handleImagenChange} />
                            {image &&
                                <Box
                                    sx={{
                                        height: "50px",
                                        width: "50px",
                                        background: "red",
                                        backdropFilter: "blur(10px)",
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "40px",
                                        borderRadius: "0 0 0 30px",
                                    }}
                                    onClick={() => {
                                        setImage(null);
                                        setImageURL(null);
                                    }}
                                >
                                    <IconButton>
                                        <CloseIcon sx={{
                                            color: "white",

                                        }} />
                                    </IconButton>
                                </Box>
                            }
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={esGratuito}
                                    onChange={(e) => setEsGratuito(e.target.checked)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Es gratuito"
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingBottom: "100px"
            }}>
                <Typography variant="h4" sx={{
                    marginTop: "40px",
                    width: "100%",
                    marginLeft: "20px",
                    marginBottom: "20px",
                }}>Previsualizacion
                </Typography>
                <Grid container item sx={{
                    height: `${window.innerHeight}px`,
                    width: "100%",
                    overflow: "hidden",
                    position: "relative",
                }}>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        zIndex: -1,
                        position: "absolute",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                    }} />
                    <Box sx={{
                        backgroundImage: imageURL ? `url(${imageURL})` : "url(https://www.eldiario.es/clm/imagenes/2021/06/01/imagen-de-prueba_1_785x523.jpg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        transition: "background-image 0.5s",
                        width: "100%",
                        height: "100%",
                        zIndex: -2,
                        position: "absolute",
                    }} />

                    <Grid container item sx={{
                        justifyContent: "flex-start",
                        paddingY: "60px",
                        paddingLeft: { xs: "0px", md: "30px" },
                        boxSizing: "border-box",
                        flexWrap: "nowrap",
                        width: "fit-content",
                        position: "absolute",
                        bottom: 0,
                        left: `${0 * - 300}px`,
                        transition: "left 0.5s",
                    }}>
                        <Evento evento={
                            {
                                nombre: nombre || "Nombre del evento",
                                categoria: categoria?.nombre || "Categoria",
                                descripcion: descripcion || "Descripción del evento",
                                imagen: imageURL || "https://www.eldiario.es/clm/imagenes/2021/06/01/imagen-de-prueba_1_785x523.jpg",
                                desde: desde ? desde.format("YYYY-MM-DD") : "0000-00-00",
                                hasta: hasta ? hasta.format("YYYY-MM-DD") : null,
                                hora: hora ? hora.format("HH:mm") : "00:00",
                                lugar: lugar || "Lugar",
                                link: link || "Link",
                                es_gratuito: esGratuito || false,
                                esPreview: true,
                            }
                        } isFirst={true} setScroll={() => { }} index={0} setModal={setModal} />
                    </Grid>
                    <Box sx={{
                        marginTop: { xs: "60px", md: "80px" },
                        marginLeft: { xs: "20px", md: "60px" },
                        width: "600px",
                        maxWidth: "calc(100vw - 40px)",
                        overflow: "hidden",
                    }}>
                        <Grid container item sx={{
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            flexDirection: { xs: "column", md: "row" },
                        }}>
                            <Box>
                                <Typography color="textSecondary" sx={{
                                    fontSize: { xs: "1.5vh", md: "14px" },
                                    color: "#ddd"
                                }}>
                                    {categoria?.nombre || "Categoria"}
                                </Typography>
                                <Typography color={"white"} sx={{
                                    width: "100%",
                                    WebkitLineClamp: 2,
                                    fontWeight: "bold",
                                    fontSize: { xs: "2.6vh", md: "28px" },
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "webkit-box",
                                }}>
                                    {nombre || "Nombre del evento"}
                                </Typography>
                            </Box>
                        </Grid>

                        <Typography variant="body1" color="#fff"
                            paragraph
                            sx={{
                                marginTop: { xs: "5px", md: "10px" },
                                fontSize: { xs: "1.8vh", md: "18px" },
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 6,
                                WebkitBoxOrient: "vertical",
                                textAlign: "justify",
                            }}
                        >
                            {descripcion || "Descripción del evento"}
                            <Button
                                sx={{
                                    backgroundColor: "transparent",
                                    color: "white",
                                    height: "fit-content",
                                    paddingX: { xs: "3px", md: "10px" },
                                    paddingY: { xs: "1px", md: "3px" },
                                    fontSize: { xs: "10px", md: "14px" },
                                    '&:hover': {
                                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                                    },
                                    boxSizing: "border-box",
                                    marginLeft: "10px",
                                    borderRadius: "50px",
                                    border: "1px solid white",
                                    textWrap: "nowrap",
                                }}
                                onClick={() => setModal(
                                    {
                                        nombre: nombre || "Nombre del evento",
                                        categoria: categoria?.nombre || "Categoria",
                                        descripcion: descripcion || "Descripción del evento",
                                        imagen: imageURL || "https://www.eldiario.es/clm/imagenes/2021/06/01/imagen-de-prueba_1_785x523.jpg",
                                        desde: desde ? desde.format("YYYY-MM-DD") : "0000-00-00",
                                        hasta: hasta ? hasta.format("YYYY-MM-DD") : "0000-00-00",
                                        hora: hora ? hora.format("HH:mm") : "00:00",
                                        lugar: lugar || "Lugar",
                                        link: link || "Link",
                                        es_gratuito: esGratuito || false,
                                        esPreview: true,
                                    })
                                }
                            >
                                Ver más
                            </Button>
                        </Typography>
                    </Box>
                    <Box sx={{
                        position: "absolute",
                        bottom: 10,
                        left: "50%",
                        transform: "translateX(-50%)",
                        display: "flex",
                        gap: "10px",
                    }}>
                        <Box sx={{
                            width: "7px",
                            height: "7px",
                            borderRadius: "50%",
                            backgroundColor: "white",
                            cursor: "pointer",
                        }}
                        />
                    </Box>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        width: "fit-content",
                        backgroundColor: "#e9722e",
                        marginTop: "20px",
                        marginLeft: "20px",
                        paddingX: "40px",

                        "&:hover": {
                            backgroundColor: "#e9722e",
                        }
                    }}
                    onClick={enviarEvento}
                >
                    Enviar
                </Button>
            </Box>
            <Modal
                open={alerta.open}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                disableAutoFocus
                onClose={() => setAlerta({ open: false, mensaje: "", color: "" })}
            >
                <Paper sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "20px",
                    width: "80%",
                    maxWidth: "400px",
                }}>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                    }}>
                        {alerta.icono}
                        <Typography variant="h5" fontWeight={"bold"}>{alerta.titulo}</Typography>
                    </Box>
                    <Typography variant="h6" sx={{
                    }}>{alerta.mensaje}</Typography>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}>
                        <Button
                            sx={{
                                width: "100px",
                                backgroundColor: "#e9722e",

                                "&:hover": {
                                    backgroundColor: "#e9722e",

                                }
                            }}
                            variant="contained" color="primary"
                            onClick={() => {
                                if (alerta.ok) {
                                    navigate("/");
                                }
                                setAlerta({ open: false, mensaje: "", titulo: "", icono: null });
                            }}
                        >Aceptar</Button>
                    </Box>
                </Paper>
            </Modal>
            <ModalEvento
                evento={
                    {
                        nombre: nombre || "Nombre del evento",
                        categoria: categoria?.nombre || "Categoria",
                        descripcion: descripcion || "Descripción del evento",
                        imagen: imageURL || "https://www.eldiario.es/clm/imagenes/2021/06/01/imagen-de-prueba_1_785x523.jpg",
                        desde: desde ? desde.format("YYYY-MM-DD") : "0000-00-00",
                        hasta: hasta ? hasta.format("YYYY-MM-DD") : "0000-00-00",
                        hora: hora ? hora.format("HH:mm") : "00:00",
                        lugar: lugar || "Lugar",
                        link: link || "Link",
                        es_gratuito: esGratuito || false,
                        esPreview: true,
                    }
                }
                open={modal !== null}
                onClose={() => setModal(null)}
            />
        </Grid>
    )
}