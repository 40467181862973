import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import LogoMuni from "../assets/muni2.svg"
import LogoA52 from "../assets/logo52.png"
import LogoBool from "../assets/logo.png"
import { useLocation, useNavigate } from "react-router-dom";

export default function Footer() {
    const wppNumbre = "5493534145438"

    const navigate = useNavigate()
    const location = useLocation()


    return (
        <Grid container
            item sx={{
                backgroundColor: "rgb(30,30,30)",
                color: "white",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                paddingBottom: "100px",
            }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center"
            }}>
                <Box sx={{
                    width: "60%",
                    paddingY: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    gap: "80px",
                    flexWrap: "wrap"
                }}>
                    <img src={LogoA52} alt="Logo Agenda 52" style={{ height: "100px" }} />
                    <img src={LogoMuni} alt="Logo Municipalidad" style={{ height: "100px" }} />

                </Box>
                {location.pathname !== "/formulario" &&
                    <Box sx={{
                        width: "40%",
                        minWidth: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}>
                        <Button sx={{
                            backgroundColor: "white",
                            color: "#e9722e",
                            display: "flex",
                            flexDirection: "column",
                            height: "fit-content",
                            padding: "30px",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",

                            "&:hover": {
                                backgroundColor: "#e9722e",
                                color: "white",
                            }
                        }}
                            onClick={() => {
                                navigate("/formulario")
                            }}
                        >
                            <Typography sx={{
                                fontWeight: "bold",
                                fontSize: "20px",
                            }}>¿REALIZÁS UN EVENTO?
                            </Typography>
                            <Typography sx={{
                                fontSize: "16px",
                            }}>Contanos acá</Typography>

                        </Button>
                    </Box>
                }
            </Box>

            <Box sx={{
                width: "100%",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                gap: "40px",
                marginTop: { xs: 10, md: 0 },
                marginBottom: { xs: 10, md: 0 },
                flexWrap: "wrap"
            }}>
                <IconButton sx={{
                    color: "white",
                    display: "flex",
                    gap: "10px",
                    borderRadius: "10px",
                    paddingX: "30px",
                    minWidth: "300px"
                }}
                    onClick={() => window.open("https://www.instagram.com/agenda52.villamaria/", "_blank")}
                >
                    <InstagramIcon sx={{
                        fontSize: "35px",
                    }} />
                    <Typography variant="h6" sx={{
                        fontSize: "30px",
                    }}>Instagram</Typography>
                </IconButton>

                <IconButton sx={{
                    color: "white",
                    display: "flex",
                    gap: "10px",
                    borderRadius: "10px",
                    paddingX: "30px",
                    minWidth: "300px"
                }}
                    onClick={() => window.open("https://www.facebook.com/agenda52villamaria", "_blank")}
                >
                    <FacebookIcon sx={{
                        fontSize: "35px",
                    }} />
                    <Typography variant="h6" sx={{
                        fontSize: "30px",
                    }}>Facebook</Typography>
                </IconButton>
                <IconButton sx={{
                    color: "white",
                    display: "flex",
                    gap: "10px",
                    borderRadius: "10px",
                    paddingX: "30px",
                    minWidth: "300px"
                }}
                    onClick={() => window.open(`https://wa.link/4yeovd`, "_blank")}
                >
                    <WhatsAppIcon sx={{
                        fontSize: "35px",
                    }} />
                    <Typography variant="h6" sx={{
                        fontSize: "30px",
                    }}>WhatsApp</Typography>
                </IconButton>
            </Box>
            <Box sx={{
                width: "100%",
                display: "flex",
                marginTop: "50px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexWrap: "wrap",
                paddingX: "20px",
                boxSizing: "border-box",
            }}>
                <Typography sx={{
                    color: "white",
                    fontSize: "20px",
                }}>
                    Desarrollado por
                </Typography>
                <img src={LogoBool} alt="Logo Bool" style={{ height: "100px", maxWidth: "250px", objectFit: "contain", cursor: "pointer" }}
                    onClick={() => window.open("https://www.instagram.com/boolean_systems?igsh=MWc4czRqMGFzNmlxZA==", "_blank")}
                />
            </Box>
        </Grid>
    )
}
