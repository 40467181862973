import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";

import LogoHeader from "../assets/logoheader.png";
import LogoMuni from "../assets/muni.svg";
import { useNavigate } from "react-router-dom";

export default function Header(props) {
    const [isVisible, setIsVisible] = useState(false);

    const navigate = useNavigate()

    const { mainRef } = props;

    useEffect(() => {
        const handleScroll = () => {
            if (mainRef.current) {
                const { scrollTop } = mainRef.current;

                if (scrollTop > 40) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            }
        };

        if (mainRef.current) {
            mainRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (mainRef.current) {
                mainRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }
        , [mainRef]);

    return (
        <Box sx={{
            background: isVisible ?
                "rgba(0,0,0,.9)"
                :
                "linear-gradient(to bottom, rgba(0,0,0,.9) 0%, rgba(0,0,0,0) 100%)",
            height: "70px",
            width: "100%",
            boxSizing: "border-box",
            position: "fixed",
            top: 0,
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
            transition: "background 1s",
            justifyContent: "space-between"
        }}>
            <img src={LogoHeader} alt="Logo Agenda 52" style={{ height: "40px", cursor: "pointer" }} onClick={() => navigate("/")} />
            <img src={LogoMuni} alt="Logo Municipalidad" style={{ height: "40px", cursor: "pointer" }} onClick={() => window.open("https://villamaria.gob.ar/", "_blank")} />
        </Box>
    )
}