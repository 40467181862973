import { Box, Grid, IconButton, Typography } from "@mui/material";
import { host } from "../../utils/Fetchs";

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export default function Evento(props) {
    const { evento, setModal } = props;

    return (
        <Box sx={{
            backgroundColor: "white",
            width: "295px",
            height: "360px",
            borderRadius: "16px",
            marginLeft: "28px",
            overflow: "hidden",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
            cursor: "pointer",
            "&:hover": {
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
            }
        }}
            onClick={() => setModal(evento)}
        >
            <Box sx={{
                width: "100%",
                height: "50%",
                overflow: "hidden",
                position: "relative",
            }}>
                <img src={`${host}${evento.imagen}`} alt={evento.nombre} style={{
                    transform: `translateY(${evento.imagen_y}px)`,
                    width: "100%",
                    objectFit: "cover",
                }} />
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: "50%",
                paddingBottom: "200px",
                paddingX: "16px",
                paddingTop: "10px",
            }}>
                <Grid container item sx={{
                    flexDirection: "column",
                }}>
                    <Typography color="textSecondary" sx={{
                        fontSize: "10px",
                    }}>
                        {evento.categoria.nombre}
                    </Typography>
                    <Typography sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                    }}>
                        {evento.nombre}
                    </Typography>
                </Grid>
                <Grid container item sx={{
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginTop: "10px",
                    gap: "6px",
                    alignItems: "center",
                    height: "100%",
                    paddingBottom: "20px",

                }}>

                    <Typography color="textSecondary" sx={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        textAlign: "justify",
                    }}>
                        {evento.descripcion}
                    </Typography>
                    <IconButton sx={{
                        width: "40px",
                        height: "40px",
                        color: "black",
                        borderRadius: "50%",
                    }}>
                        <ExpandCircleDownIcon />
                    </IconButton>
                </Grid>
            </Box>
        </Box>
    )
}