import { Box, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Evento from "./Evento";
import { useEffect, useRef, useState } from "react";
import useFetch, { host } from "../../utils/Fetchs";

import ModalEvento from "../eventosPorFecha/ModalEvento";

export default function Carrousel(props) {
    const { eventos, setModal } = props;

    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        setScroll(0);
    }, [eventos])

    function scrollRight() {
        if (!eventos.length || eventos.length === 1) return;
        if (scroll === eventos.length - 1) {
            setScroll(0);
            return;
        }
        setScroll(scroll + 1);
    }

    function scrollLeft() {
        if (!eventos.length || eventos.length === 1) return;
        if (scroll === 0) {
            setScroll(eventos.length - 1);
            return;
        }
        setScroll(scroll - 1);
    }

    if (!eventos.length) return null

    return (
        <Grid container item sx={{
            height: "500px",
            width: "100%",
            overflow: "hidden",
            position: "relative",
        }}>
            <Grid container item sx={{
                justifyContent: "flex-start",
                paddingY: "60px",
                paddingLeft: { xs: "0px", md: "30px" },
                boxSizing: "border-box",
                flexWrap: "nowrap",
                width: "fit-content",
                position: "absolute",
                bottom: 0,
                left: `${scroll * - 323}px`,
                transition: "left 0.5s",
            }}>
                {eventos?.map((evento, index) => {
                    return (
                        <Evento key={index} evento={evento} isFirst={index === scroll} setScroll={setScroll} index={index} setModal={setModal} />
                    )
                })}
            </Grid>

            {
                eventos.length > 1 &&
                <Box sx={{
                    position: "absolute",
                    bottom: 10,
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    gap: "10px",
                }}>
                    {eventos.map((evento, index) => {
                        return (
                            <Box key={index} sx={{
                                width: "7px",
                                height: "7px",
                                borderRadius: "50%",
                                backgroundColor: index === scroll ? "#333" : "#ccc",
                                cursor: "pointer",
                            }}
                                onClick={() => setScroll(index)}
                            />
                        )
                    })}
                </Box>
            }
            {
                eventos.length > 1 &&
                <IconButton sx={{
                    position: "absolute",
                    top: { xs: "45%", md: "50%" },
                    left: 20,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    padding: "15px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                    }
                }} onClick={scrollLeft}>
                    <KeyboardArrowLeftIcon sx={{
                        width: { xs: "25px", md: "40px" },
                        height: { xs: "25px", md: "40px" },
                    }} />
                </IconButton>
            }
            {
                eventos.length > 1 &&
                <IconButton
                    sx={{
                        position: "absolute",
                        top: { xs: "45%", md: "50%" },
                        right: 20,
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                        padding: "15px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.6)",
                        }

                    }} onClick={scrollRight}>
                    <KeyboardArrowRightIcon sx={{
                        width: { xs: "25px", md: "40px" },
                        height: { xs: "25px", md: "40px" },
                    }} />
                </IconButton>
            }
        </Grid >
    )
}