import React, { useEffect, useRef } from 'react';


import { Grid } from '@mui/material';
import Header from './components/partials/Header';
import Footer from './components/partials/Footer';
import Herramientas from './components/layouts/herramientas/Herramientas';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'

import Banner2 from './components/assets/banner2.png';
import Download from './components/pages/download/Download';
import Formulario from './components/pages/formulario/Formulario';
import EventosAdmin from './components/pages/EventosAdmin/EventosAdmin';
import Home from './components/pages/home/Home';
import BaseLayout from './components/layouts/BaseLayout';

function App() {
  return (
    <Routes>
      <Route path='/download/107/' element={
        <Download
          linkGoogle="https://play.google.com/store/apps/details?id=avilio.villa.maria&hl=es_AR"
          linkIOS="https://apps.apple.com/ar/app/107-vm/id6505078770?l=en-GB"
        />}
      />
      <Route path='/download/entupse/' element={
        <Download
          linkGoogle="hhttps://play.google.com/store/apps/details?id=ar.utn.frvm.mvm&hl=es_AR"
          linkIOS="https://apps.apple.com/ar/app/villa-mar%C3%ADa-ciudad/id1579192962?l=en-GB"
        />}
      />
      <Route element={<BaseLayout />}>
        <Route path="/" element={
          <Home />
        } />
        <Route path='/formulario' element={
          <Formulario />
        } />
      </Route>
      <Route path='/eventos' element={
        <EventosAdmin />
      } />
    </Routes>
  );
}

export default App;
