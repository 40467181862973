import { Box, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Evento from "./Evento";
import { useEffect, useRef, useState } from "react";
import useFetch, { host } from "../../utils/Fetchs";

import ModalEvento from "../eventosPorFecha/ModalEvento";

export default function Eventos(props) {
    const [eventos, setEventos] = useState([]);
    const [loading, setLoading] = useState(true);

    const { setModal } = props;

    const { getFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}eventos/proximos/`)
            .then(data => {
                setEventos(data.eventos);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }, []);

    const [scroll, setScroll] = useState(0);
    let intervalId = useRef(null);

    const startAutoScroll = () => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
        intervalId.current = setInterval(() => {
            if (scroll === eventos.length - 1) {
                setScroll(0);
                return;
            }
            setScroll(scroll + 1);
        }, 5000);
    };

    useEffect(() => {
        if (!eventos.length) return;
        startAutoScroll();
        return () => clearInterval(intervalId.current);
    }, [scroll, eventos]);



    function scrollRight() {
        if (!eventos.length || eventos.length === 1) return;
        if (scroll === eventos.length - 1) {
            setScroll(0);
            return;
        }
        setScroll(scroll + 1);
        startAutoScroll();
    }

    function scrollLeft() {
        if (!eventos.length || eventos.length === 1) return;
        if (scroll === 0) {
            setScroll(eventos.length - 1);
            return;
        }
        setScroll(scroll - 1);
        startAutoScroll();
    }

    if (loading) {
        return (
            <Grid container item sx={{
                height: "100vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#212121",
            }}>
                <CircularProgress />
            </Grid>
        )
    }

    if (!eventos.length) return null

    return (
        <Grid container item sx={{
            height: `${window.innerHeight}px`,
            width: "100%",
            overflow: "hidden",
            position: "relative",
        }}>
            <Box sx={{
                width: "100%",
                height: "100%",
                zIndex: -1,
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
            }} />
            <Box sx={{
                backgroundImage: `url(${host}${eventos[scroll].imagen})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                transition: "background-image 0.5s",
                width: "100%",
                height: "100%",
                zIndex: -2,
                position: "absolute",
            }} />

            <Grid container item sx={{
                justifyContent: "flex-start",
                paddingY: "60px",
                paddingLeft: { xs: "0px", md: "30px" },
                boxSizing: "border-box",
                flexWrap: "nowrap",
                width: "fit-content",
                position: "absolute",
                bottom: 0,
                left: `${scroll * - 300}px`,
                transition: "left 0.5s",
            }}>
                {eventos?.map((evento, index) => {
                    return (
                        <Evento key={index} evento={evento} isFirst={index === scroll} setScroll={setScroll} index={index} setModal={setModal} />
                    )
                })}
            </Grid>
            <Box sx={{
                marginTop: { xs: "60px", md: "80px" },
                marginLeft: { xs: "20px", md: "60px" },
                width: "600px",
                maxWidth: "calc(100vw - 40px)",
                overflow: "hidden",
            }}>
                <Grid container item sx={{
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: { xs: "column", md: "row" },
                }}>
                    <Box>
                        <Typography color="textSecondary" sx={{
                            fontSize: { xs: "1.5vh", md: "14px" },
                            color: "#ddd"
                        }}>
                            {eventos[scroll].categoria}
                        </Typography>
                        <Typography color={"white"} sx={{
                            width: "100%",
                            WebkitLineClamp: 2,
                            fontWeight: "bold",
                            fontSize: { xs: "2.6vh", md: "28px" },
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "webkit-box",
                        }}>
                            {eventos[scroll].nombre}
                        </Typography>
                    </Box>
                </Grid>

                <Typography variant="body1" color="#fff"
                    paragraph
                    sx={{
                        marginTop: { xs: "5px", md: "10px" },
                        fontSize: { xs: "1.8vh", md: "18px" },
                        display: "-webkit-box",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                        textAlign: "justify",
                    }}
                >
                    {eventos[scroll].descripcion}
                    <Button
                        sx={{
                            backgroundColor: "transparent",
                            color: "white",
                            height: "fit-content",
                            paddingX: { xs: "3px", md: "10px" },
                            paddingY: { xs: "1px", md: "3px" },
                            fontSize: { xs: "10px", md: "14px" },
                            '&:hover': {
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                            },
                            boxSizing: "border-box",
                            marginLeft: "10px",
                            borderRadius: "50px",
                            border: "1px solid white",
                            textWrap: "nowrap",
                        }}
                        onClick={() => setModal({
                            ...eventos[scroll],
                        })
                        }
                    >
                        Ver más
                    </Button>
                </Typography>
            </Box>
            {
                eventos.length > 1 &&
                <IconButton sx={{
                    position: "absolute",
                    top: { xs: "45%", md: "50%" },
                    left: 20,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    padding: "15px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                    }
                }} onClick={scrollLeft}>
                    <KeyboardArrowLeftIcon sx={{
                        width: { xs: "25px", md: "40px" },
                        height: { xs: "25px", md: "40px" },
                    }} />
                </IconButton>
            }
            {
                eventos.length > 1 &&
                <IconButton
                    sx={{
                        position: "absolute",
                        top: { xs: "45%", md: "50%" },
                        right: 20,
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                        padding: "15px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.6)",
                        }

                    }} onClick={scrollRight}>
                    <KeyboardArrowRightIcon sx={{
                        width: { xs: "25px", md: "40px" },
                        height: { xs: "25px", md: "40px" },
                    }} />
                </IconButton>
            }
        </Grid >
    )
}