import { Box, Grid, IconButton, Paper, Typography, Autocomplete, TextField, Button, Modal, FormControlLabel, Checkbox, FormControl, OutlinedInput, InputLabel } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import CloseIcon from '@mui/icons-material/Close';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import AddIcon from '@mui/icons-material/Add';

import useFetch, { host } from "../../utils/Fetchs";

import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from "react-router-dom";

import Evento from "../../layouts/eventos/Evento"

export default function EventosAdmin() {
    const [eventos, setEventos] = useState([]);
    const [modal, setModal] = useState(null);

    const { getFetch, postFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}eventos/acomodar/`)
            .then(data => setEventos(data.eventos))
    }, [])

    return (
        <Box sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            overflowY: "auto",
            padding: "20px",
            boxSizing: "border-box",
        }}>
            {eventos.length > 0 && eventos.map((evento, index) => (
                <Evento evento={evento} key={evento.id} isAcomodar={true} onClick={() => { setModal(evento) }} />
            ))}
            <Modal
                open={modal !== null}
                onClose={() => setModal(null)}
                disableAutoFocus={true}
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    paddingY: "30px",
                    paddingRight: "30px",
                    boxSizing: "border-box",
                    display: "flex",
                    borderRadius: "16px",
                    backgroundColor: "#333",
                    flexDirection: "column",
                }}

                >
                    <Box sx={{
                        display: "flex",
                        gap: "30px",
                    }}>
                        <Evento evento={modal} isAcomodar={true} onClick={() => { }} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            justifyContent: "center",
                            gap: "20px",
                        }}>
                            <IconButton onClick={() => {
                                setModal({
                                    ...modal,
                                    imagen_y: modal.imagen_y - 10
                                })
                            }}
                                sx={{
                                    backgroundColor: "#999",
                                }}
                            >
                                <AddIcon sx={{
                                    color: "white",
                                }} />
                            </IconButton>
                            <IconButton onClick={() => {
                                setModal({
                                    ...modal,
                                    imagen_y: modal.imagen_y + 10
                                })
                            }} sx={{
                                backgroundColor: "#999",
                            }}
                            >
                                <RemoveIcon sx={{
                                    color: "white",
                                }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                    }}>
                        <Button onClick={() => {
                            postFetch(`${host}eventos/acomodar/`, {
                                eventos: [{
                                    id: modal.id,
                                    imagen_y: modal.imagen_y
                                }]
                            })
                                .then(data => {
                                    setEventos(data.eventos)
                                    setModal(null)
                                })
                        }
                        }
                            variant="contained"
                        >
                            Guardar
                        </Button>
                    </Box>
                </Box>
            </Modal >
        </Box >
    )
}