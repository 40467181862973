import { Box, Button, Typography, Modal, Grid, IconButton, useMediaQuery } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import Logo107 from "../../assets/107logo.png"
import LogoEntupse from "../../assets/logoentupse.png"
import LogoFixer from "../../assets/logofixer.png"

import QRFixer from "../../assets/qrfixer.png"
import QR107 from "../../assets/107qr.png"
import QRentupse from "../../assets/qrentupse.png"

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CloseIcon from '@mui/icons-material/Close';

import { isAndroid, isIOS } from 'react-device-detect';

import { useState } from "react";

export default function Herramientas() {
    const [modalHerramienta, setModalHerramienta] = useState(null);
    const [modalQR, setModalQR] = useState(null);

    const isMobile = useMediaQuery('(max-width:600px)');

    const herramientas = [
        {
            herramienta: "Emergencias",
            nombre: "107",
            // iframe: "http://localhost:3001",
            logo: Logo107,
            descripcion: "Esta aplicación le permitirá utilizar los servicios de emergencias médicas de 107 Villa María. Una vez registrado en la plataforma usted podrá convocar los servicios presionando solamente el botón rojo de emergencia.",
            qr: QR107,
            links: [
                {
                    nombre: "Google Play",
                    url: "https://play.google.com/store/apps/details?id=avilio.villa.maria&hl=es_AR"
                },
                {
                    nombre: "App Store",
                    url: "https://apps.apple.com/ar/app/107-vm/id6505078770?l=en-GB"
                }
            ]
        },
        {
            herramienta: "Estacionamiento",
            nombre: "Entupse",
            qr: QRentupse,
            logo: LogoEntupse,
            descripcion: "Esta herramienta le permitira colaborar con el ordenamiento vial, conocer recorridos y frecuencias del transporte urbano y administrar sus minutos de estacionamiento vehicular por medio de una billetera electrónica.",
            links: [
                {
                    nombre: "Google Play",
                    url: "https://play.google.com/store/apps/details?id=ar.utn.frvm.mvm&hl=es_AR"
                },
                {
                    nombre: "App Store",
                    url: "https://apps.apple.com/ar/app/villa-mar%C3%ADa-ciudad/id1579192962?l=en-GB"
                }
            ]
        },
        {
            herramienta: "Servicios",
            nombre: "Fixer",
            qr: QRFixer,
            logo: LogoFixer,
            descripcion: "Encuentra profesionales de confianza con facilidad. Accede a perfiles detallados, con ubicación cercana y comunicación directa para una experiencia de contratación sin complicaciones ni retrasos. Fixer, la plataforma que conecta calidad con eficiencia.",
            link: "https://fixersoluciones.com/?localidad=3"
        }
    ]

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            padding: "20px",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                marginTop: { xs: "20px", md: "0px" },
                gap: "60px",
                paddingY: "40px",
                boxSizing: "border-box",
                width: '100%',
            }}>
                {
                    herramientas.map((herramienta, index) => (
                        <Button key={index} sx={{
                            backgroundColor: "white",
                            color: "#000",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                            borderRadius: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",

                            height: "250px",
                            width: "250px",


                            "&:hover": {
                                backgroundColor: "white",
                                transform: "scale(1.05)",
                            },

                            transition: "all 0.3s",
                        }}
                            onClick={() => setModalHerramienta(herramienta)}
                        >
                            <Typography sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                textAlign: "center"
                            }}>{herramienta.herramienta}</Typography>
                            <img src={herramienta.logo} alt={herramienta.herramienta} style={{

                                height: "60%",
                                width: "80%",
                                objectFit: "contain"
                            }} />
                        </Button>
                    ))
                }
            </Box>
            <Modal
                open={modalHerramienta !== null && modalQR === null}
                onClose={() => setModalHerramienta(null)}
                disableAutoFocus
            >
                {modalHerramienta?.iframe ?
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}>
                        <iframe title='showcase' src={modalHerramienta?.iframe} style={{
                            borderRadius: "24px",
                            height: isMobile ? "80vh" : "90vh",
                            maxWidth: "90vw",
                            aspectRatio: "9/16",
                            border: "none",
                            background: "white"
                        }} />
                    </Box>
                    :
                    <Box sx={{
                        backgroundColor: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "90vw",
                        maxWidth: "700px",
                        boxSizing: "border-box",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "16px",
                        display: "flex",
                        padding: { xs: "20px", md: "40px" },
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}>
                        <Box sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "1px",
                            zIndex: 1,
                        }}>
                            <IconButton
                                onClick={() => setModalHerramienta(null)}
                                sx={{
                                    position: "absolute",
                                    top: "15px",
                                    right: "15px",
                                    backgroundColor: "rgba(0, 0, 0, .5)",
                                    backdropFilter: "blur(10px)",
                                    color: "#fff",
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            height: "100%",
                            width: { xs: "100%", md: "30%" },
                            height: { xs: "200px", md: "300px" },
                        }}>
                            <img src={modalHerramienta?.logo} alt={modalHerramienta?.nombre} style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }} />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: { md: "40px" },
                            justifyContent: "space-between",
                            width: { xs: "100%m", md: "70%" },
                            gap: "20px",
                            boxSizing: "border-box",
                        }}>
                            <Grid container item sx={{
                                flexDirection: "column",

                            }}>
                                <Typography sx={{
                                    fontSize: "26px",
                                    fontWeight: "bold",
                                }}>
                                    {modalHerramienta?.nombre}
                                </Typography>
                                <Typography sx={{
                                    color: "#333",
                                    marginTop: "20px",
                                    fontSize: "16px",
                                    textAlign: "justify",
                                }}>
                                    {modalHerramienta?.descripcion}
                                </Typography>
                            </Grid>
                            <Grid container item sx={{
                                justifyContent: "space-between",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: "100%",
                                marginTop: "10px",
                                alignItems: "flex-end",
                                gap: "20px",
                            }}>
                                <Grid container item sx={{
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    width: "100%",
                                    marginTop: "10px",
                                    alignItems: "flex-end",
                                }}>
                                    {isAndroid && modalHerramienta?.links &&
                                        <Button sx={{
                                            backgroundColor: "#333",
                                            color: "#fff",
                                            borderRadius: "24px",
                                            padding: "8px 40px",
                                            textWrap: "nowrap",
                                        }}
                                            onClick={() => window.open(modalHerramienta.links[0].url, "_blank")}
                                        >
                                            Google Play
                                        </Button>
                                    }
                                    {isIOS && modalHerramienta?.links &&
                                        <Button sx={{
                                            backgroundColor: "#333",
                                            color: "#fff",
                                            borderRadius: "24px",
                                            padding: "8px 20px",
                                            textWrap: "nowrap",
                                        }}
                                            onClick={() => window.open(modalHerramienta.links[1].url, "_blank")}
                                        >
                                            App Store
                                        </Button>
                                    }
                                    {modalHerramienta?.link &&
                                        <Button sx={{
                                            backgroundColor: "#333",
                                            color: "#fff",
                                            borderRadius: "24px",
                                            padding: "8px 28px",

                                            "&:hover": {
                                                backgroundColor: "#444",
                                            }
                                        }}
                                            onClick={() => window.open(modalHerramienta.link, "_blank")}
                                        >
                                            Visitar
                                        </Button>
                                    }
                                    <Button sx={{
                                        backgroundColor: "#333",
                                        color: "#fff",
                                        borderRadius: "24px",
                                        padding: "8px 28px",
                                        gap: "10px",
                                        display: "flex",

                                        "&:hover": {
                                            backgroundColor: "#444",
                                        }
                                    }}
                                        onClick={() => setModalQR(modalHerramienta.qr)}
                                    >
                                        <QrCodeScannerIcon sx={{
                                            fontSize: "24px",

                                        }} />
                                        <Typography sx={{

                                            fontSize: "16px",
                                        }}>
                                            QR
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal >
            <Modal
                open={modalQR !== null}
                onClose={() => setModalQR(null)}
                disableAutoFocus
            >
                <Box sx={{
                    backgroundColor: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "90vw",
                    boxSizing: "border-box",
                    maxHeight: "500px",
                    maxWidth: "500px",
                    transform: "translate(-50%, -50%)",
                    margin: "auto",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                    alignItems: "center"
                }}>
                    <img src={modalQR} alt="QR" style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                    }} />
                </Box>
            </Modal>
        </Box >
    )
}