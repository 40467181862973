import { Grid, Typography, FormControlLabel, Checkbox, IconButton, Modal, Box, Button, TextField, useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Banner1 from '../../assets/banner1.png';

import TuneIcon from '@mui/icons-material/Tune';
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import useFetch, { host } from '../../utils/Fetchs';

import ClearIcon from '@mui/icons-material/Clear';
import ModalEvento from './ModalEvento';
import Carrousel from './Carrousel';

import { useLocation } from 'react-router-dom';

export default function EventosPorFecha(props) {
    const { setModal, eventos, categorias, categoriaActual, setCategoriaActual } = props;

    const { getFetch } = useFetch();

    const isMobile = useMediaQuery('(max-width:600px)');


    const [desde, setDesde] = useState(null)
    const [hasta, setHasta] = useState(null)

    function filtrarPorCategoria() {
        return eventos.filter(evento => evento.categoria.id === categoriaActual.id);
    };

    function eventosFiltradosDe8en8() {
        let eventosFiltrados = filtrarEventos();
        let eventosFiltradosDe8en8 = [];
        let i = 0;

        while (i < eventosFiltrados.length) {
            eventosFiltradosDe8en8.push(eventosFiltrados.slice(i, i + 8));
            i += 8;
        }

        return eventosFiltradosDe8en8;
    }

    const filtrarEventos = () => {
        if (!desde && !hasta) return filtrarPorCategoria(); // Si no hay fecha, devuelvo los eventos de la categoria actual 

        if (!hasta) return eventos.filter(evento => { // Si hay fecha desde, devuelvo los eventos que empiecen despues de la fecha desde
            let eventoDesde = dayjs(evento.desde);
            let eventoHasta = dayjs(evento.hasta);

            console.log(eventoDesde, eventoHasta)


            if (!eventoHasta.isValid()) return eventoDesde.isAfter(dayjs(desde).subtract(1, 'day')) // Si no hay fecha eventoHasta, devuelvo los eventos que empiecen despues de la fecha desde

            return eventoHasta.isAfter(dayjs(desde).subtract(1, 'day'))
        })

        if (!desde) return eventos.filter(evento => {
            let eventoDesde = dayjs(evento.desde);

            return eventoDesde.isBefore(dayjs(hasta).add(1, 'day'))
        })


        return eventos.filter(evento => {
            let eventoDesde = dayjs(evento.desde);
            let eventoHasta = dayjs(evento.hasta);

            if (!eventoHasta.isValid()) return eventoDesde.isAfter(dayjs(desde).subtract(1, 'day')) && eventoDesde.isBefore(dayjs(hasta).add(1, 'day'))

            return eventoDesde.isAfter(dayjs(desde).subtract(1, 'day')) && eventoHasta.isBefore(dayjs(hasta).add(1, 'day')) ||
                eventoHasta.isAfter(dayjs(desde).subtract(1, 'day')) && eventoHasta.isBefore(dayjs(hasta).add(1, 'day')) ||
                eventoDesde.isBefore(dayjs(desde).subtract(1, 'day')) && eventoHasta.isAfter(dayjs(hasta).add(1, 'day'))
        });
    };

    return (
        <Grid container sx={{
            minHeight: "100vh",
            width: "100%",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "nowrap",
        }}>

            <img src={Banner1} alt="Banner" style={{
                width: "100vw",
                objectFit: "cover",
            }} />
            <Grid container item sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }
            }}>
                {categorias.map(categoria => (
                    <Button key={categoria.id} sx={{
                        backgroundColor: (desde || hasta) ? "#f5f5f5" : categoriaActual.id === categoria.id ? "#333" : "#f5f5f5",
                        color: (desde || hasta) ? "#333" : categoriaActual.id === categoria.id ? "#fff" : "#333",
                        paddingX: "20px",
                        paddingY: "20px",
                        borderRadius: "0px",
                        flex: 1,

                        "&:hover": {
                            backgroundColor: (desde || hasta) ? "#333" : categoriaActual.id === categoria.id ? "#333" : "#f5f5f5",
                            color: (desde || hasta) ? "#fff" : categoriaActual.id === categoria.id ? "#fff" : "#333",
                        }
                    }}
                        onClick={() => {
                            setCategoriaActual(categoria)
                            setDesde(null)
                            setHasta(null)
                        }}
                    >
                        {categoria.nombre}
                    </Button>
                ))}
            </Grid>
            <Grid container item sx={{
                height: "fit-content",
                display: "flex",
                justifyContent: "space-evenly",
                paddingY: "20px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                position: "relative",
                flexWrap: "wrap",
                gap: "20px",
                paddingX: "30px",
            }}>
                <Box sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "300px",
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DatePicker
                            label="Desde"
                            sx={{
                                width: "100%",
                                maxWidth: "300px",
                            }}
                            value={desde}
                            onChange={(newValue) => setDesde(newValue)}
                            inputFormat="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                    {desde && isMobile &&
                        <IconButton
                            onClick={() => setDesde(null)}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)"
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    }
                </Box>
                <Box sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "300px",
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DatePicker
                            label="Hasta"
                            value={hasta}
                            sx={{
                                width: "100%",
                                maxWidth: "300px",
                            }}
                            onChange={(newValue) => setHasta(newValue)}
                            minDate={desde}
                            inputFormat="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                    {hasta && isMobile &&
                        <IconButton
                            onClick={() => setHasta(null)}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)"
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    }
                </Box>
            </Grid>
            {eventosFiltradosDe8en8().map((eventos, index) => (
                <Carrousel eventos={eventos} key={index} setModal={setModal} />
            ))}
        </Grid >
    )
}