import { Box, IconButton, Modal, useMediaQuery, Grid, Typography, Button } from "@mui/material";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ClearIcon from '@mui/icons-material/Clear';
import { host } from "../../utils/Fetchs";
import FormatDate from "../../utils/FormatDate";
import ShareIcon from '@mui/icons-material/IosShare'

export default function ModalEvento(props) {
    const { evento, onClose, open } = props;

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Modal
            open={open}
            onClose={onClose}
            disableAutoFocus
        >
            <Box sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                width: "100%",
                maxWidth: "90vw",
                transform: "translate(-50%, -50%)",
                borderRadius: "16px",
                boxSizing: "border-box",
                height: "auto",
                maxHeight: "80vh",
                overflow: "hidden",
            }}>
                <Box sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "1px",
                    zIndex: 1,
                }}>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                            backgroundColor: "rgba(0, 0, 0, .5)",
                            backdropFilter: "blur(10px)",
                            color: "#fff",
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Box sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                    boxSizing: "border-box",
                }}>
                    <img src={`${host}${evento?.imagen}`} alt={evento?.nombre} style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                    }} />
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, .7)",
                        backdropFilter: "blur(10px)",
                        position: "absolute",
                    }} />
                </Box>
                <Box sx={{
                    position: "relative",
                    display: "flex",
                    flexWrap: "wrap",
                    boxSizing: "border-box",
                    marginTop: { xs: "-5px", md: "0px" },
                    maxHeight: "80vh",
                    overflowY: { xs: "auto", md: "hidden" },
                }}>
                    < Box sx={{
                        width: "100%",
                        maxHeight: { md: "500px" },
                        display: "flex",
                        justifyContent: { xs: "center", md: "flex-start" },
                        flexDirection: { xs: "column", md: "row" },
                        padding: { md: "30px" },
                        boxSizing: "border-box",
                    }}>

                        <img src={evento?.esPreview ? evento?.imagen :
                            `${host}${evento?.imagen}`} alt={evento?.nombre} style={{
                                height: "100%",
                                maxHeight: isMobile ? "300px" : "100%",
                                maxWidth: isMobile ? "100%" : "50%",
                                objectFit: "contain",
                                borderRadius: `16px 16px ${isMobile ? "0 0" : "16px 16px"}`,
                            }} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "0px",
                            width: "100%",
                            boxSizing: "border-box",
                            justifyContent: "space-between",
                            padding: { xs: "20px", md: "30px" },
                        }}>
                            <Grid container item sx={{
                                flexDirection: "column",

                            }}>
                                <Typography sx={{
                                    fontSize: { xs: "20px", md: "30px" },
                                    fontWeight: "bold",
                                }}>
                                    {evento?.nombre}
                                </Typography>
                                <Typography sx={{
                                    color: "#333",
                                    marginTop: "10px",
                                    fontSize: { xs: "16px", md: "20px" },
                                    textAlign: "justify",
                                }}>
                                    {evento?.descripcion}
                                </Typography>
                            </Grid>
                            <Grid container item sx={{
                                justifyContent: "space-between",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: "100%",
                                marginTop: "10px",
                                alignItems: "flex-end",
                            }}>
                                <Grid container item sx={{
                                    gap: "6px",
                                    flexDirection: "column",
                                }}>
                                    < Typography color="textSecondary" sx={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <CalendarMonthIcon sx={{
                                            fontSize: "24px",
                                            marginRight: "5px",
                                        }} />
                                        {FormatDate(evento?.desde)} {FormatDate(evento?.hasta) ? `al ${FormatDate(evento?.hasta)}` : ""}
                                    </Typography>
                                    <Typography color="textSecondary" sx={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <AccessTimeIcon sx={{
                                            fontSize: "24px",
                                            marginRight: "5px",
                                        }} />
                                        {evento?.hora.slice(0, 5)} hs
                                    </Typography>
                                    <Typography color="textSecondary" sx={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <LocationOnIcon sx={{
                                            fontSize: "24px",
                                            marginRight: "5px",
                                        }} />
                                        {evento?.lugar}
                                    </Typography>
                                    {evento?.es_gratuito && <Typography color="textSecondary" sx={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <AttachMoneyIcon sx={{
                                            fontSize: "24px",
                                            marginRight: "5px",
                                        }} />
                                        Gratuito
                                    </Typography>
                                    }
                                </Grid>
                                <Box sx={{
                                    display: "flex",
                                    gap: "10px",
                                }}>
                                    <IconButton
                                        onClick={() => {
                                            try {
                                                navigator.share({
                                                    title: evento?.nombre,
                                                    text: `${evento?.nombre}\n\n${evento?.descripcion}\n`,
                                                    url: window.location.href
                                                })
                                            }
                                            catch (error) {
                                                console.error(error)
                                            }
                                        }}
                                    >
                                        <ShareIcon />
                                    </IconButton>
                                    {evento?.link && <Button sx={{
                                        backgroundColor: "#333",
                                        color: "#fff",
                                        borderRadius: "24px",
                                        padding: "8px 28px",

                                        "&:hover": {
                                            backgroundColor: "#444",
                                        }
                                    }}
                                        onClick={() => window.open(evento?.link, "_blank")}
                                    >
                                        Ir
                                    </Button>
                                    }
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}