import { isAndroid, isIOS } from 'react-device-detect';
import { useEffect } from "react";
import { useNavigate, useNavigation } from 'react-router-dom';

export default function Download(props) {
    const { linkGoogle, linkIOS } = props;

    const navigate = useNavigate();

    useEffect(() => {
        if (isAndroid) {
            window.location.href = linkGoogle
        }
        if (isIOS) {
            window.location.href = linkIOS
        }

        navigate('/')
    }, [])

    return null
}