import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Header from '../../partials/Header';
import Footer from '../../partials/Footer';
import Herramientas from '../../layouts/herramientas/Herramientas';
import Eventos from '../../layouts/eventos/Eventos';
import EventosPorFecha from '../../layouts/eventosPorFecha/EventosPorFecha';
import Banner2 from '../../assets/banner2.png';
import ModalEvento from '../../layouts/eventosPorFecha/ModalEvento';

import { useLocation, useNavigate } from 'react-router-dom';

import useFetch, { host } from '../../utils/Fetchs';
import Chat from './Chat';


export default function Home(props) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const eventoId = searchParams.get("evento");

    const [eventos, setEventos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [categoriaActual, setCategoriaActual] = useState({});

    const navigate = useNavigate();

    const { getFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}eventos/`)
            .then(data => setEventos(data.eventos))
            .catch(error => console.log(error));

        getFetch(`${host}categorias/`)
            .then(data => {
                let categoriasIndex = data.categorias.map((categoria, index) => ({ ...categoria, index }));
                categoriasIndex = categoriasIndex.sort((a, b) => {
                    if (a.id === 6) return -1;

                    return a.id - b.id;
                }
                );
                setCategorias(categoriasIndex);
                setCategoriaActual(categoriasIndex[0]);
            })
            .catch(error => console.log(error));
    }, []);


    useEffect(() => {
        if (eventoId) {
            let evento = eventos.find(evento => evento.id === parseInt(eventoId));

            if (evento) setModal(evento);
        }
    }, [eventos]);

    const [modal, setModal] = useState(null)

    function handleSetModal(evento) {
        console.log(evento);

        if (evento) {
            setModal(evento);
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('evento', evento.id);
            navigate(`?${searchParams.toString()}`, { replace: true });
        } else {
            setModal(null);
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('evento');
            navigate(`?${searchParams.toString()}`, { replace: true });
        }
    }

    return (
        <Box>
            <Eventos
                setModal={handleSetModal}
            />
            <EventosPorFecha
                eventos={eventos}
                categorias={categorias}
                categoriaActual={categoriaActual}
                setCategoriaActual={setCategoriaActual}
                setModal={handleSetModal}
            />
            <img src={Banner2} alt="Banner" style={{
                width: "100%",
                objectFit: "cover"
            }} />
            <Herramientas />
            <ModalEvento evento={modal} open={modal !== null} onClose={() => handleSetModal(null)} />
            {/* <Chat /> */}
        </Box>
    )
}