import { Box, Grid, Typography } from "@mui/material";
import { host } from "../../utils/Fetchs";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ModalEvento from "../eventosPorFecha/ModalEvento";
import FormatDate from "../../utils/FormatDate";
import { useState } from "react";

export default function Evento(props) {
    const { evento, isFirst, setScroll, index, setModal, onClick, isAcomodar = false } = props;

    return (
        <Box sx={{
            backgroundColor: "white",
            width: "250px",
            height: "310px",
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
            marginLeft: "50px",
            cursor: "pointer",
            '&:hover': {
                transform: isAcomodar ? "" : isFirst ? "scale(1.2)" : "scale(1.05)",
            },
            transform: isFirst ? "scale(1.2)" : "scale(1)",

        }}
            onClick={onClick ? onClick :
                evento.link ? () => window.open(evento.link, "_blank") : () => setModal(evento)}
        >   <Box sx={{
            width: "100%",
            height: "50%",
            overflow: "hidden",
            position: "relative",
        }}>
                <img src={evento.esPreview ? evento.imagen : `${host}${evento.imagen}`}
                    alt={evento.nombre} style={{
                        transform: `translateY(${evento.imagen_y}px)`,
                        width: "100%",
                        objectFit: "cover",
                    }} />
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: "50%",
                paddingBottom: "200px",
                paddingX: "16px",
            }}>
                <Grid container item sx={{
                    flexDirection: "column",
                }}>
                    <Typography color="textSecondary" sx={{
                        fontSize: "10px",
                    }}>
                        {evento.categoria}
                    </Typography>
                    <Typography sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                    }}>
                        {evento.nombre}
                    </Typography>
                </Grid>
                <Grid container item sx={{
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginTop: "5px",
                    gap: "2px",
                }}>
                    {evento.es_gratuito && <Typography color="textSecondary" sx={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "green",
                    }}>
                        <AttachMoneyIcon sx={{
                            fontSize: "16px",
                            marginRight: "5px",
                            color: "green",
                            fontWeight: "bold",
                        }} />
                        Gratuito
                    </Typography>
                    }
                    <Typography color="textSecondary" sx={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <CalendarMonthIcon sx={{
                            fontSize: "16px",
                            marginRight: "5px",
                        }} />
                        {FormatDate(evento.desde)} {FormatDate(evento.hasta) ? `al ${FormatDate(evento.hasta)}` : ""}
                    </Typography>
                    <Typography color="textSecondary" sx={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <AccessTimeIcon sx={{
                            fontSize: "16px",
                            marginRight: "5px",
                        }} />
                        {evento.hora.slice(0, 5)} hs
                    </Typography>
                    <Typography color="textSecondary" sx={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <LocationOnIcon sx={{
                            fontSize: "16px",
                            marginRight: "5px",
                        }} />
                        {evento.lugar}
                    </Typography>
                </Grid>
            </Box>
        </Box >
    )
}