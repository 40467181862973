import { Box } from '@mui/material';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';


export default function BaseLayout() {
    const mainRef = useRef(null);

    const location = useLocation();
    const [prevPathname, setPrevPathname] = useState(location.pathname);

    useEffect(() => {
        if (location.pathname !== prevPathname) {
            if (mainRef.current) {
                mainRef.current.scrollTo(0, 0);
            }
            setPrevPathname(location.pathname);
        }
    }, [location.pathname, prevPathname]);
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
        }}
            ref={mainRef}
        >
            <Header mainRef={mainRef} />
            <div>
                <Outlet />
            </div>
            <Footer />
        </Box>
    )
}